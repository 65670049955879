import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { url2section } from 'ion-sections'
import loadable from '@loadable/component'
import NewspaperButton from '../../../assets/images/buttons/thestar-button.png'
import { DefaultSectionPageSkeletonWrapper, ArticlePageSkeleton } from '../components/Skeletons'

const TitleSectionPage = loadable(() => import('../pages/TitleSectionPage'), {
  fallback: <DefaultSectionPageSkeletonWrapper />
})
const TagPage  = loadable(() => import('../pages/TagPage'))
const Article = loadable(() => import('../pages/Article'), {
  fallback: <ArticlePageSkeleton />
})

const RenderArticle = (props) => {
  const { contentKey } = useParams()
  return <Article {...props} contentKey={contentKey} />
}

const TheStar = (props) => (
  <Switch>
    <Route path='/the-star' exact render={() => (
      <TitleSectionPage {...props}
        section='the-star'
        relatedSections={[{ id: 'the-star/news' }, { id: 'the-star/world' }, { id: 'the-star/sport' }, { id: 'the-star/lifestyle' }, { id: 'the-star/opinion', label: 'Opinion' }]}
        title='The Star'
        description='-'
        publication='The Star'
        newspaperTitle='The Star on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://thestar.zinioapps.com/shop'
      />
    )} />
    <Route path='/the-star/news' exact render={() => (
      <TitleSectionPage {...props}
        section='the-star/news'
        relatedSections={[{ id: 'the-star' }, { id: 'the-star/world' }, { id: 'the-star/sport' }, { id: 'the-star/lifestyle' }, { id: 'the-star/opinion', label: 'Opinion' }]}
        title='The Star'
        description='-'
        publication='The Star'
        newspaperTitle='The Star on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://thestar.zinioapps.com/shop'
      />
    )} />
    <Route path='/the-star/world' exact render={() => (
      <TitleSectionPage {...props}
        section='the-star/world'
        relatedSections={[{ id: 'the-star' }, { id: 'the-star/news' }, { id: 'the-star/sport' }, { id: 'the-star/lifestyle' }, { id: 'the-star/opinion', label: 'Opinion' }]}
        title='The Star'
        description='-'
        publication='The Star'
        newspaperTitle='The Star on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://thestar.zinioapps.com/shop'
      />
    )} />
    <Route path='/the-star/sport' exact render={() => (
      <TitleSectionPage {...props}
        section='the-star/sport'
        relatedSections={[{ id: 'the-star' }, { id: 'the-star/news' }, { id: 'the-star/world' }, { id: 'the-star/lifestyle' }, { id: 'the-star/opinion', label: 'Opinion' }]}
        title='The Star'
        description='-'
        publication='The Star'
        newspaperTitle='The Star on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://thestar.zinioapps.com/shop'
      />
    )} />
    <Route path='/the-star/lifestyle' exact render={() => (
      <TitleSectionPage {...props}
        section='the-star/lifestyle'
        relatedSections={[{ id: 'the-star' }, { id: 'the-star/news' }, { id: 'the-star/world' }, { id: 'the-star/sport' }, { id: 'the-star/opinion', label: 'Opinion' }]}
        title='The Star'
        description='-'
        publication='The Star'
        newspaperTitle='The Star on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://thestar.zinioapps.com/shop'
      />
    )} />
    <Route path='/the-star/opinion' exact render={() => (
      <TitleSectionPage {...props}
        section='the-star/opinion'
        relatedSections={[{ id: 'the-star' }, { id: 'the-star/news' }, { id: 'the-star/world' }, { id: 'the-star/sport' }, { id: 'the-star/lifestyle' }]}
        title='The Star'
        description='-'
        publication='The Star'
        newspaperTitle='The Star on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://thestar.zinioapps.com/shop'
      />
    )} />
    <Route path={'/the-star/:section([a-zA-Z0-9-/]+)/:title*([-])*:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})'} exact render={() => (
      <RenderArticle {...props} oovvuu='4c0314aa-87fa-4a57-8152-521cc269c23c' />
    )} />
    <Route path={'/the-star/:title*([-])*:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})'} exact render={() => (
      <RenderArticle {...props} oovvuu='4c0314aa-87fa-4a57-8152-521cc269c23c' />
    )} />
    <Route path={'/the-star/:section([a-zA-Z0-9-/]+)/:title*([-])*:contentKey([0-9]{5,})'} exact render={() => (
      <RenderArticle {...props} />
    )} />
    <Route path={'/the-star/:title*([-])*:contentKey([0-9]{5,})'} exact render={() => (
      <RenderArticle {...props} />
    )} />
    <Route path={'/the-star/:section([a-zA-Z0-9-/]+)'} exact render={() => (
      <TagPage {...props}
        section={url2section(props.location.pathname)}
        url={props.location.pathname}
      />
    )} />
  </Switch>
)

export default TheStar
